<template>
  <div class="successPay">
    <el-card class="box-card">
      <div slot="header">
        <span v-if="payStatus == 'SUCCESS' || apStatus == 'SUCCESS'" class="textTitle"
          >支付成功</span
        >
        <span v-else class="textTitle">支付失败</span>
      </div>
      <div v-if="payStatus == 'SUCCESS' || apStatus == 'SUCCESS'" class="succIcon">
        <div class="title">订单支付成功</div>
        <div class="icon"><i class="el-icon-circle-check"></i></div>
        <div class="orderDetail">您的订单：{{ orderId }}支付成功</div>
        <div class="time">
          还有 <span class="second">{{ time }}</span> 秒离开页面
        </div>
      </div>
      <div v-else class="succIcon">
        <div class="title">订单支付失败</div>
        <div class="icon"><i class="el-icon-error"></i></div>
        <div class="orderDetail">您的订单：支付失败</div>
        <div class="time">
          还有 <span class="second">{{ time }}</span> 秒离开页面
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import to from 'await-to'
import { getWXCodePay } from '@/api/college'
export default {
  data() {
    return {
      orderId: this.$route.query.orderId,
      payStatus: this.$route.query.payStatus, //微信返回的订单状态
      out_trade_no: this.$route.query.out_trade_no, //支付宝返回的订单号
      interval: null,
      pathTime: null,
      apStatus: '',
      time: 5,
    }
  },
  created() {
    if (this.out_trade_no) {
      this.getWXCodePayStatus()
    }
  },
  mounted() {
    //实现轮询
    this.interval = window.setInterval(() => {
      if (this.out_trade_no) {
        this.getWXCodePayStatus()
      }
    }, 3000)
    let path = sessionStorage.getItem('paySuccessPath')
    this.pathTime = setInterval(() => {
      this.time--
      if (this.time < 1) {
        clearInterval(this.pathTime)
        this.$router.push(path)
      }
    }, 1000)
  },
  beforeDestroy() {
    //清除轮询
    clearInterval(this.interval)
    clearInterval(this.pathTime)
    this.interval = null
  },
  methods: {
    async getWXCodePayStatus() {
      const [res, err] = await to(getWXCodePay({ orderNo: this.out_trade_no }))
      if (err) {
        this.apStatus = false
        return this.$message({ type: 'error', message: '充值失败！' })
      }
      if (res && res.data.result == 'SUCCESS') {
        this.apStatus = res.data.result
        this.$message({ message: '充值成功！', type: 'success' })
      }
      clearInterval(this.interval)
      this.interval = null
    },
  },
}
</script>

<style scoped lang="scss">
.successPay {
  .textTitle {
    font-size: 18px;
  }
  .succIcon {
    margin: auto;
    width: 300px;
    text-align: center;
    padding: 200px 0;
    .title {
      font-size: 24px;
    }
    .icon {
      margin: 40px 0;
    }
    .orderDetail {
      font-size: 14px;
    }
    .time {
      font-size: 18px;
      margin-top: 20px;
      .second {
        color: #ff7b33;
      }
    }
    ::v-deep {
      .el-icon-circle-check {
        font-size: 100px;
        color: #67c23a;
      }
      .el-icon-error {
        font-size: 100px;
        color: #f56c6c;
      }
    }
  }
}
</style>
